import React from 'react'
import { Link } from 'gatsby'
import { FaArrowLeft } from 'react-icons/fa';

const BackButton = ({ buttonLink }) => {
  return (
    <div className='mt-12'>
      <Link to={buttonLink}>
        <button className="flex items-center justify-center self-end text-xs px-5 py-3 mt-6 border border-transparent font-bold rounded-md text-white uppercase tracking-widest bg-red-800 hover:bg-red-900">
          <FaArrowLeft className='mr-4' /> Back
        </button>
      </Link>
    </div>
  )
}

export default BackButton