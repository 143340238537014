import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

import ProductNew from '../Common/ProductNew';

const ProductCollateralSectionUpdate = () => {
  const data = useStaticQuery(graphql`
    query ProductCollateralSectionUpdateQuery {
      prismicSalesSupportDocumentsProductCollateral {
        data {
          section_title
          section_description {
            richText
          }
        }
      }
      prismicSalesSupportDocumentsProductCollateralErm {
        data {
          title
          description
          button_text
        }
      }
      prismicSalesSupportDocumentsProductCollateralCw {
        data {
          title
          description
          button_text
        }
      }
      prismicSalesSupportDocumentsProductCollateralDr {
        data {
          title
          description
          button_text
        }
      }
      prismicSalesSupportDocumentsProductCollateralCdl {
        data {
          title
          description
          button_text
        }
      }
      prismicSalesSupportDocumentsProductCollateralSa {
        data {
          title
          description
          button_text
        }
      }
      prismicSalesSupportDocumentsProductCollateralTa {
        data {
          title
          description
          button_text
        }
      }
      prismicSalesSupportDocumentsProductCollateralMarc {
        data {
          title
          description
          button_text
        }
      }
      prismicSalesSupportDocumentsProductCollateralDpp {
        data {
          title
          description
          button_text
        }
      }
      prismicSalesSupportDocumentsProductCollateralTr {
        data {
          title
          description
          button_text
        }
      }
    }
  `);

  const doc = data.prismicSalesSupportDocumentsProductCollateral
  const erm = data.prismicSalesSupportDocumentsProductCollateralErm
  const cw = data.prismicSalesSupportDocumentsProductCollateralCw
  const dr = data.prismicSalesSupportDocumentsProductCollateralDr
  const cdl = data.prismicSalesSupportDocumentsProductCollateralCdl
  const sa = data.prismicSalesSupportDocumentsProductCollateralSa
  const ta = data.prismicSalesSupportDocumentsProductCollateralTa
  const marc = data.prismicSalesSupportDocumentsProductCollateralMarc
  const dpp = data.prismicSalesSupportDocumentsProductCollateralDpp
  const tr = data.prismicSalesSupportDocumentsProductCollateralTr

  return (
    <>
      <div className="w-full max-w-screen-lg mt-6 mb-16">
        <h2 className='text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold mb-6'>{doc.data.section_title}</h2>
        {doc.data.section_description
        ?
        <RichText
          render={doc.data.section_description.richText}
          htmlSerializer={htmlSerializer}
        />
        :
        null
        }
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-0 md:gap-2 xl:gap-8'>
        <ProductNew
          title={erm.data.title}
          productText={erm.data.description}
          buttonLink="/sales-support-documents/collateral/erm"
          buttonText={erm.data.button_text}
        />

        <ProductNew
          title={cw.data.title}
          productText={cw.data.description}
          buttonLink="/sales-support-documents/collateral/cw"
          buttonText={cw.data.button_text}
        />

        <ProductNew
          title={dr.data.title}
          productText={dr.data.description}
          buttonLink="/sales-support-documents/collateral/dr"
          buttonText={dr.data.button_text}
        />

        <ProductNew
          title={cdl.data.title}
          productText={cdl.data.description}
          buttonLink="/sales-support-documents/collateral/cdl"
          buttonText={cdl.data.button_text}
        />

        <ProductNew
          title={sa.data.title}
          productText={sa.data.description}
          buttonLink="/sales-support-documents/collateral/sa"
          buttonText={sa.data.button_text}
        />

        <ProductNew
          title={ta.data.title}
          productText={ta.data.description}
          buttonLink="/sales-support-documents/collateral/ta"
          buttonText={ta.data.button_text}
        />

        <ProductNew
          title={marc.data.title}
          productText={marc.data.description}
          buttonLink="/sales-support-documents/collateral/marc"
          buttonText={marc.data.button_text}
        />

        <ProductNew
          title={dpp.data.title}
          productText={dpp.data.description}
          buttonLink="/sales-support-documents/collateral/dpp"
          buttonText={dpp.data.button_text}
        />

        <ProductNew
          title={tr.data.title}
          productText={tr.data.description}
          buttonLink="/sales-support-documents/collateral/tr"
          buttonText={tr.data.button_text}
        />
      </div>
    </>
  )
}

export default ProductCollateralSectionUpdate